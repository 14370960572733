// extracted by mini-css-extract-plugin
export var benefit = "Benefit-module--benefit--b81d7";
export var benefitCont = "Benefit-module--benefitCont--cc880";
export var btn = "Benefit-module--btn--0038d";
export var cards = "Benefit-module--cards--13e6e";
export var description = "Benefit-module--description--6cb46";
export var future = "Benefit-module--future--b8707";
export var heading = "Benefit-module--heading--a8988";
export var ser = "Benefit-module--ser--9aad5";
export var talent = "Benefit-module--talent--d199a";
export var text = "Benefit-module--text--747d2";
export var tick = "Benefit-module--tick--c090c";