import { graphql,Script } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout3"
import Banner from "../components/legacy-application/Banner"
import Benefit from "../components/legacy-application/Benefit"
import Solutions from "../components/legacy-application/Solutions"
import ModernizedServices from "../components/legacy-application/ModernizedServices"
import Faqs from "../components/common/Faqs2"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
     <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="idle"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        image="https://invozone-backend.s3.amazonaws.com/Portfolio_059cb9635f.png"
      />
    </>
  )
}
const legacyApplication = ({ data }) => {
  console.log(data)
  const heroSection = data?.strapiPage?.sections[0]
  const solutions = data?.strapiPage?.sections[1]
  const challanges = data?.strapiPage?.sections[2]
  const benefit = data?.strapiPage?.sections[3]
  const faq = data?.strapiPage?.sections[4]

  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 1280) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 1280)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <MainLayout >
      <Banner strapiData={heroSection} />
      <Solutions strapiData={solutions}/>
      <ModernizedServices strapiData={challanges}/>
      <Benefit strapiData={benefit}/>
      <Faqs strapiData={faq} />
      <ContactSales bgColor="#F8F8F8" />
    </MainLayout>
  )
}

export const query = graphql`
  query legacyApplication {
    strapiPage(slug: { eq: "legacy-application-modernization" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default legacyApplication