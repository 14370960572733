// extracted by mini-css-extract-plugin
export var accImg = "Solutions-module--accImg--47db1";
export var description = "Solutions-module--description--4d89a";
export var engineeringSQA = "Solutions-module--engineeringSQA--dd30b";
export var head = "Solutions-module--head--365bc";
export var heading = "Solutions-module--heading--eb5c2";
export var nextGenRight = "Solutions-module--nextGenRight--24f20";
export var quesColor = "Solutions-module--quesColor--81426";
export var quesColorSelected = "Solutions-module--quesColorSelected--aabcd";
export var ser = "Solutions-module--ser--a6964";
export var someColor = "Solutions-module--someColor--98059";
export var textColor = "Solutions-module--textColor--5f399";