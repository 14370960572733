// extracted by mini-css-extract-plugin
export var Heading = "Banner-module--Heading--cde7d";
export var bannerCon = "Banner-module--bannerCon--627d6";
export var bannerHeading = "Banner-module--bannerHeading--5594b";
export var btn = "Banner-module--btn--c7286";
export var description = "Banner-module--description--46423";
export var fintech = "Banner-module--fintech--89745";
export var freshprepHeading = "Banner-module--freshprepHeading--d6d13";
export var imageAnimation = "Banner-module--imageAnimation--a89c3";
export var portfolioAppWorkBanner = "Banner-module--portfolioAppWorkBanner--917e1";
export var subTitle = "Banner-module--subTitle--840c3";
export var topToBottomBounce = "Banner-module--topToBottomBounce--f34c8";