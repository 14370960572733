// extracted by mini-css-extract-plugin
export var SliderWidth = "ModernizedServices-module--SliderWidth--2d59e";
export var arrowImg = "ModernizedServices-module--arrowImg--53778";
export var card = "ModernizedServices-module--card--1148e";
export var cardMian = "ModernizedServices-module--cardMian--fb80a";
export var cardWrapper = "ModernizedServices-module--cardWrapper--1b7c4";
export var cards = "ModernizedServices-module--cards--5373b";
export var description = "ModernizedServices-module--description--f88c8";
export var heading = "ModernizedServices-module--heading--a1288";
export var headingPremium = "ModernizedServices-module--headingPremium--37573";
export var mainWorkMernStack = "ModernizedServices-module--mainWorkMernStack--4ecc8";
export var mernIconContainer = "ModernizedServices-module--mernIconContainer--6ae96";
export var mernIconContainerLeft = "ModernizedServices-module--mernIconContainerLeft--aef0c";
export var portfolioArrowIcon = "ModernizedServices-module--portfolioArrowIcon--90439";
export var portfolioArrowIconCover = "ModernizedServices-module--portfolioArrowIconCover--82834";
export var portfolioArrowRightIconCover = "ModernizedServices-module--portfolioArrowRightIconCover--01d3f";
export var premiumImg = "ModernizedServices-module--premiumImg--3eda8";
export var providingImg = "ModernizedServices-module--providingImg--1de85";
export var ser = "ModernizedServices-module--ser--ce459";
export var teamButton = "ModernizedServices-module--teamButton--e0a98";
export var trailBg = "ModernizedServices-module--trailBg--5722e";