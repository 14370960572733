import React from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  //   const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={`${styles.portfolioAppWorkBanner}`}>
      <Container className={styles.bannerCon}>
        <div className={`${styles.fintech} `}>
          <Container>
            <Row className="align-items-center ">
              <Col xl={6} lg={12}>
                <div className={styles.Heading}>
                  <p
                    className={styles.subTitle}
                    dangerouslySetInnerHTML={{
                      __html: strapiData?.title,
                    }}
                  ></p>
                  <h1
                    className={`${styles.bannerHeading} `}
                    dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
                  />
                  <p
                    className={`${styles.description} `}
                    dangerouslySetInnerHTML={{
                      __html: strapiData?.description?.description,
                    }}
                  />
                </div>
                <Link to="/contact-us/" className={styles.btn}>
                Speak To An Expert
                  <img
                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                    decoding="async"
                    loading="lazy"
                    alt="explore icon"
                  />
                </Link>
              </Col>
              <Col ml={6} lg={12}></Col>
            </Row>
          </Container>
        </div>
      </Container>
    </div>
  )
}

export default Banner
