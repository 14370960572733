import { Link } from "gatsby"
import React, { useContext } from "react"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import "./Solutions.scss"
import * as styles from "./Solutions.module.scss"

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
        >
          {children}
        </div>
        <div type="button">
          {isCurrentEventKey ? (
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/minus_faqs_134d53dce5.svg"
              }
              className={styles.quesColorSelected}
              alt="icon"
            />
          ) : (
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/plus_faqs_d842afefe2.svg"
              }
              className={styles.quesColor}
              alt="icon"
            />
          )}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const Engineering = ({ strapiData }) => {
  return (
    <div className={styles.engineeringSQA}>
      <Container>
        <p
          className={styles.ser}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle2,
          }}
        />
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />

        <Accordion>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="sqaAccordianQuality">
                {strapiData?.cards &&
                  strapiData?.cards?.map(
                    (e, i) =>
                      i < 3 && (
                        <Card key={i} border="light">
                          <ContextAwareToggle eventKey={`ek-${i}`}>
                            <div className={styles.accImg}>
                              <lottie-player
                                autoplay
                                loop
                                src={e?.image1[0]?.localFile?.publicURL}
                                style={{ height: "60px", width: "60px" }}
                              />

                              <h3 className="mb-0 py-2" type="button">
                                {e?.title}
                              </h3>
                            </div>
                          </ContextAwareToggle>
                          <Accordion.Collapse eventKey={`ek-${i}`}>
                            <Card.Body className={styles.textColor}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )
                  )}
              </div>
            </Col>
            <Col lg={6} className={styles.nextGenRight}>
              <div className="sqaAccordianQuality">
                {strapiData?.cards &&
                  strapiData?.cards?.map(
                    (e, i) =>
                      i > 2 && (
                        <Card key={i} border="light">
                          <ContextAwareToggle eventKey={`ek-${i}`}>
                            <div className={styles.accImg}>
                              <lottie-player
                                autoplay
                                loop
                                src={e?.image1[0]?.localFile?.publicURL}
                                style={{ height: "60px", width: "60px" }}
                              />

                              <h3 className="mb-0 py-2" type="button">
                                {e?.title}
                              </h3>
                            </div>
                          </ContextAwareToggle>
                          <Accordion.Collapse eventKey={`ek-${i}`}>
                            <Card.Body className={styles.textColor}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )
                  )}
              </div>
            </Col>
          </Row>
        </Accordion>
      </Container>
    </div>
  )
}

export default Engineering
