import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import * as styles from "./Benefit.module.scss"

function Benefit({ strapiData }) {
  return (
    <div className={styles.benefit}>
      <Container className="benefitCont">
        <div className={styles.future}>
          <p
            className={styles.ser}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle2,
            }}
          />
          <h2
            className={styles.heading}
            // dangerouslySetInnerHTML={{
            //   __html: strapiData?.title,
            // }}
          >
            Future Proofing your business.
          </h2>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className={styles.cards}>
            {strapiData?.cards &&
              strapiData?.cards?.map((item, index) => (
                <div className={styles.talent} key={index}>
                  <img
                    className={styles.tick}
                    src={
                      "https://invozone-backend.s3.us-east-1.amazonaws.com/check_04fb87f210.svg"
                    }
                    alt={item?.title}
                  />
                  <div className={styles.text}>
                    <p>{item?.title}</p>
                  </div>
                </div>
              ))}
          </div>
          <Link to="/contact-us/" className={styles.btn}>
            Let's Talk Benefits!
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
              decoding="async"
              loading="lazy"
              alt="explore icon"
            />
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default Benefit
